import { Experiment } from '@amplitude/experiment-js-client';
import { analytics } from './analytics';

export const runAmplitudeExperiment = async () => {
  // (1) Get your deployment's API key
  const apiKey = 'client-mSC5rul8BZbYSlCSo1P1pslEZeK8I1PO';
  // console.log('Attempting to run Amplitude Experiment');
  // (2) Initialize the experiment client

  // (3) Fetch variants for a user
  const experiment = Experiment.initialize(apiKey);

  analytics.ready(async () => {
    const userId = analytics.instance?.user().id();
    const deviceId = analytics.instance?.user().anonymousId();
    const user = {
      user_id: userId,
      device_id: deviceId,
    };
    await experiment.fetch(user);

    const flagKey = 'wordpress';
    const variant = experiment.variant(flagKey);
    if (variant.value) {
      analytics.track('$exposure', { flag_key: flagKey, variant: variant.value });
      if (variant.value !== 'control') {
        const leftCopy = variant.payload.button_left_copy;
        const rightCopy = variant.payload.button_right_copy;
        if (leftCopy) {
          document.querySelector('.df_button_left').innerText = leftCopy;
        }
        if (rightCopy) {
          document.querySelector('.df_button_right').innerText = rightCopy;
        }
      } else {
        // Flag is off, do nothing
      }
    }
  });
};
