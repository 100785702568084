import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import * as EmailValidator from 'email-validator';
import * as styles from './Apply1Form.module.scss';
import TextField from './formComponents/TextField';
import SelectField from './formComponents/SelectField';
import MCQuestion from './formComponents/MCQuestion';
import { sendContact, submitRegistrationForm } from '../api/registration';
import {
  customEventApply1Submit,
  customEventIdentifyUser,
  customEventPartialFormSubmit,
} from '../analytics/customEvents';
import validPASiteCodes from './validPASiteCodes.json';
import stateAndSiteCodes from './StateAndSiteCodeValues.json';
import { ErrorMessage } from './ErrorMessage';

export const Apply1FormDataAnalytics = () => {
  const formId = '#apply1-form-data';

  const [cohortId, setCohortId] = useState('');
  const [programCode, setProgramCode] = useState('');
  const [thankYouRedirect, setThankYouRedirect] = useState('');

  const defaults = {
    firstName: 'Type your first name',
    lastName: 'Type your last name',
    email: 'Type your email',
    phone: 'e.g. (555) 555-5555',
  };

  // fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneMasked, setPhoneMasked] = useState('');
  const [amzState, setAmzState] = useState('');
  const [amzSiteCode, setAmzSiteCode] = useState('');
  const [amzSiteCodeFreeText, setAmzSiteCodeFreeText] = useState('');
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [hasTimeCommitment, setHasTimeCommitment] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [collegeFocus, setCollegeFocus] = useState('');
  const [previousDataAnalysisPosition, setPreviousDataAnalysisPosition] = useState('');
  const [hasComputerLiteracy, setHasComputerLiteracy] = useState('');
  const [currentCertifications, setCurrentCertifications] = useState('');
  const [hasPreviousDataAnalysis, setHasPreviousDataAnalysis] = useState('');
  const [programGoal, setProgramGoal] = useState('');
  const [roleTimeline, setRoleTimeline] = useState('');
  const [programChallenges, setProgramChallenges] = useState('');
  const [careerGoal, setcareerGoal] = useState('');
  const [linkedInProfileURL, setLinkedInProfileURL] = useState('');
  const [textConsent, setTextConsent] = useState(false);
  const [callConsent, setCallConsent] = useState(false);
  const [termsConsent, setTermsConsent] = useState(false);
  const [mathQuizAnswers, setMathQuizAnswers] = useState(['', '', '', '', '', '', '']);
  const [mathQuizScore, setMathQuizScore] = useState(0.0);
  const [page, setPage] = useState(1);

  // errors
  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [amzStateError, setAmzStateError] = useState('');
  const [amzSiteCodeError, setAmzSiteCodeError] = useState('');
  const [employmentStatusError, setEmploymentStatusError] = useState('');
  const [hasTimeCommitmentError, setHasTimeCommitmentError] = useState('');
  const [currentRoleError, setCurrentRoleError] = useState('');
  const [educationLevelError, setEducationLevelError] = useState('');
  const [hasComputerLiteracyError, setHasComputerLiteracyError] = useState('');
  const [hasPrevisouDataAnalysisError, setHasPreviousDataAnalysisError] = useState('');
  const [programGoalError, setProgramGoalError] = useState('');
  const [roleTimelineError, setRoleTimelineError] = useState('');
  const [programChallengesError, setProgramChallengesError] = useState('');
  const [careerGoalError, setcareerGoalError] = useState('');
  const [linkedInProfileURLError, setLinkedInProfileURLError] = useState('');
  const [formError, setFormError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [termsConsentError, setTermsConsentError] = useState('');

  const stateNames = Object.getOwnPropertyNames(stateAndSiteCodes);
  const locationOptions = stateNames.map(state => ({ value: state, label: stateAndSiteCodes[state].state_name }));

  const siteCodeOptions = [
    { value: 'not-listed', label: 'My site code is not listed here' },
    { value: 'dont-know', label: "I don't know my site code" },
  ];
  const siteCodeList = validPASiteCodes.siteCodes;

  // remove duplicates
  const siteCodeListUnique = siteCodeList.filter((value, index, array) => array.indexOf(value) === index);
  const siteCodes = siteCodeListUnique.map(code => ({ value: code, label: code }));
  siteCodeOptions.unshift(...siteCodes);

  const redirect = React.useRef();

  useEffect(() => {
    const formOptions = document.querySelector(formId);
    if (
      !formOptions.dataset.cohortid ||
      !formOptions.dataset.programcode ||
      !formOptions.dataset.thankyouredirect ||
      !formOptions.dataset.ineligibleredirect
    ) {
      // eslint-disable-next-line
      alert('Error: Form must have data-cohortId, data-programCode, and data-thankYouRedirect attributes.');
    } else {
      setCohortId(formOptions.dataset.cohortid);
      setProgramCode(formOptions.dataset.programcode);
      setThankYouRedirect(formOptions.dataset.thankyouredirect);
      redirect.current = formOptions.dataset.thankyouredirect;
    }
  }, []);

  // Effect to handle scrolling when the page changes to 2
  useEffect(() => {
    if (page === 2) {
      const formEl = document.getElementById('apply1-form-data');
      if (formEl) formEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [page]);

  const validateWordCount = (text, maxWords = 250) => {
    const wordCount = text.trim().split(/\s+/).length;
    return wordCount <= maxWords;
  };

  const handleChangeFirstName = e => {
    setFirstNameError('');
    setFirstName(e.target.value);
  };

  const handleChangeLastName = e => {
    setLastNameError('');
    setLastName(e.target.value);
  };

  const handleChangeEmail = e => {
    setEmailError('');
    setEmail(e.target.value);
  };

  const handleChangePhone = e => {
    setPhoneError('');
    const phoneNum = e.target.value.replace(/\D/g, '');
    setPhone(phoneNum.length > 10 ? phoneNum.slice(0, 10) : phoneNum);
    // this masks the phone input to (xxx) xxx-xxxx
    const val = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const phoneFormat = !val[2] ? val[1] : `(${val[1]}) ${val[2]}${val[3] ? `-${val[3]}` : ''}`;
    setPhoneMasked(phoneFormat);
  };

  const handleChangeAmzState = e => {
    setAmzStateError('');
    setAmzState(e.target.value);
  };

  const handleChangeAmzSiteCode = e => {
    setAmzSiteCodeError('');
    setAmzSiteCode(e.target.value);
  };

  const handletextConsent = () => {
    setTextConsent(!textConsent);
  };

  const handleCallConsent = () => {
    setCallConsent(!callConsent);
  };

  const handleTermsConsent = () => {
    setTermsConsentError('');
    setTermsConsent(!termsConsent);
  };

  const handleChangeHasTimeCommitment = e => {
    setHasTimeCommitmentError('');
    setHasTimeCommitment(e.target.value);
  };

  const handleChangeEmploymentStatus = e => {
    setEmploymentStatusError('');
    setEmploymentStatus(e.target.value);
  };

  const handleChangeCurrentRole = e => {
    setCurrentRole(e.target.value);
  };

  const handleChangeRoleTimeline = e => {
    setRoleTimeline(e.target.value);
  };

  const handleChangeEducationLevel = e => {
    setEducationLevel(e.target.value);
  };

  const handleChangePreviousDataAnalysisPosition = e => {
    setPreviousDataAnalysisPosition(e.target.value);
  };

  const handleChangeHasComputerLiteracy = e => {
    setHasComputerLiteracy(e.target.value);
  };

  const handleChangeHasPreviousDataAnalysis = e => {
    setHasPreviousDataAnalysisError('');
    setHasPreviousDataAnalysis(e.target.value);
  };

  const handleChangeProgramGoal = e => {
    const text = e.target.value;

    if (!validateWordCount(text)) {
      setProgramGoalError('Ensure your text does not exceed 250 words.');
    } else {
      setProgramGoalError('');
      setProgramGoal(text);
    }
  };

  const handleChangeProgramChallenges = e => {
    setProgramChallengesError('');
    setProgramChallenges(e.target.value);
  };

  const handleChangecareerGoal = e => {
    setcareerGoalError('');
    setcareerGoal(e.target.value);
  };

  const handleLinkedInProfileURL = e => {
    setLinkedInProfileURLError('');
    setLinkedInProfileURL(e.target.value);
  };

  const handleValidate = field => {
    let valid = true;

    if (field === 'email') {
      if (!email) {
        setEmailError(`Email is required`);
        valid = false;
      } else if (!EmailValidator.validate(email)) {
        setEmailError(`This email doesn't look right`);
        valid = false;
      } else {
        setEmailError('');
      }
    }
    if (field === 'firstName') {
      if (!firstName) {
        setFirstNameError('First name is required');
        valid = false;
      } else {
        setFirstNameError('');
      }
    }

    if (field === 'lastName') {
      if (!lastName) {
        setLastNameError('Last name is required');
        valid = false;
      } else {
        setLastNameError('');
      }
    }

    if (field === 'phone') {
      if (!phone) {
        setPhoneError('Phone is required');
        valid = false;
      } else if (phone.length < 10) {
        setPhoneError('Phone must be at least 10 digits');
        valid = false;
      } else {
        setPhoneError('');
      }
    }

    if (field === 'amzState') {
      if (!amzState) {
        setAmzStateError('State is required');
        valid = false;
      } else {
        setAmzStateError('');
      }
    }
    if (field === 'amzSiteCode') {
      if (!amzSiteCode) {
        setAmzSiteCodeError('Site code is required');
        valid = false;
      } else {
        setAmzSiteCodeError('');
      }
    }

    if (field === 'currentRole') {
      if (!currentRole) {
        setCurrentRoleError('This is required');
        valid = false;
      } else {
        setCurrentRoleError('');
      }
    }
    if (field === 'employmentStatus') {
      if (!employmentStatus) {
        setEmploymentStatusError('This is required');
        valid = false;
      } else {
        setEmploymentStatusError('');
      }
    }
    if (field === 'hasTimeCommitment') {
      if (!hasTimeCommitment) {
        setHasTimeCommitmentError('This is required');
        valid = false;
      } else {
        setHasTimeCommitmentError('');
      }
    }
    if (field === 'educationLevel') {
      if (!educationLevel) {
        setEducationLevelError('This is required');
        valid = false;
      } else {
        setEducationLevelError('');
      }
    }
    if (field === 'hasComputerLiteracy') {
      if (!hasComputerLiteracy) {
        setHasComputerLiteracyError('This is required');
        valid = false;
      } else {
        setHasComputerLiteracyError('');
      }
    }
    if (field === 'hasPreviousDataAnalysis') {
      if (!hasPreviousDataAnalysis) {
        setHasPreviousDataAnalysisError('This is required');
        valid = false;
      } else {
        setHasPreviousDataAnalysisError('');
      }
    }
    if (field === 'programGoal') {
      if (!programGoal) {
        setProgramGoalError('This is required');
        valid = false;
      } else {
        setProgramGoalError('');
      }
    }

    if (field === 'roleTimeline') {
      if (!roleTimeline) {
        setRoleTimelineError('This is required');
        valid = false;
      } else {
        setRoleTimelineError('');
      }
    }
    if (field === 'careerGoal') {
      if (!careerGoal) {
        setcareerGoalError('This is required');
        valid = false;
      } else {
        setcareerGoalError('');
      }
    }
    if (field === 'programChallenges') {
      if (!programChallenges) {
        setProgramChallengesError('This is required');
        valid = false;
      } else {
        setProgramChallengesError('');
      }
    }
    if (field === 'termsConsent') {
      if (!termsConsent) {
        setTermsConsentError(`You must agree to Pathstream's terms of use and privacy policy before continuing.`);
        valid = false;
      } else {
        setTermsConsentError('');
      }
    }

    return valid;
  };

  useEffect(() => {
    const qa = ['B', 'C', 'C', 'A', 'A', 'D', 'B'];
    let score = 0.0;
    for (let i = 0; i < qa.length; i++) {
      if (qa[i] === mathQuizAnswers[i]) {
        score++;
      }
    }
    setMathQuizScore(score / qa.length);
  }, [mathQuizAnswers]);

  const validateAll = () => {
    if (
      handleValidate('firstName') &&
      handleValidate('lastName') &&
      handleValidate('email') &&
      handleValidate('phone') &&
      handleValidate('amzState') &&
      handleValidate('amzSiteCode') &&
      handleValidate('currentRole') &&
      handleValidate('employmentStatus') &&
      handleValidate('hasTimeCommitment') &&
      handleValidate('educationLevel') &&
      handleValidate('collegeFocus') &&
      handleValidate('hasComputerLiteracy') &&
      handleValidate('hasPreviousDataAnalysis') &&
      handleValidate('programGoal') &&
      handleValidate('roleTimeline') &&
      handleValidate('careerGoal') &&
      handleValidate('programChallenges') &&
      handleValidate('termsConsent')
    ) {
      return true;
    }
    return false;
  };

  const handleNextPage = () => {
    const valid = validateAll();
    if (valid && page === 1) {
      const formData = {
        cohort_id: cohortId,
        program_code: programCode,
        first_name: firstName,
        last_name: lastName,
        email: email.trim().toLowerCase(),
        call_consent: callConsent,
        text_consent: textConsent,
        terms_consent: termsConsent,
        phone,
        entry: 'lead_form',
      };
      sendContact(formData, status => {
        if (status.includes('contact_id')) {
          // console.log('contact submitted successfully');
        }
      });
      customEventIdentifyUser(email, firstName, lastName, phone); // calls analytics.identify
      customEventPartialFormSubmit({ form: formId, ...formData });
      setPage(2);
      setFormError(false);
    } else if (!valid) {
      setFormError(true);
    }
    if (page === 2) {
      setPage(1);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const allAnswersFilled = mathQuizAnswers.every(answer => answer !== '');

    if (validateAll() && allAnswersFilled) {
      setFormError(false);
      setButtonDisabled(true);
      const formData = {
        cohort_id: cohortId,
        program_code: programCode,
        email: email.trim().toLowerCase(),
        first_name: firstName,
        last_name: lastName,
        phone,
        text_consent: textConsent,
        call_consent: callConsent,
        terms_consent: termsConsent,
        external_partner_data: {
          form_url: window.location.href,
          amazon_state: amzState,
          amazon_site_code: amzSiteCode === 'not-listed' ? amzSiteCodeFreeText : amzSiteCode,
          current_role: currentRole,
          employment_status: employmentStatus,
          has_time_commitment: hasTimeCommitment,
          education_level: educationLevel,
          college_focus: collegeFocus,
          previous_data_analysis_position: previousDataAnalysisPosition,
          has_computer_literacy: hasComputerLiteracy,
          current_certifications: currentCertifications,
          has_previous_data_analysis: hasPreviousDataAnalysis,
          program_goal: programGoal,
          role_timeline: roleTimeline,
          program_challenges: programChallenges,
          career_goal: careerGoal,
          linkedin_profile_url: linkedInProfileURL,
          amazon_math_quiz_version: 'v2',
          amazon_math_quiz_answers: mathQuizAnswers.toString(),
          amazon_math_quiz_score: mathQuizScore,
        },
      };

      customEventIdentifyUser(email, firstName, lastName, phone);
      customEventApply1Submit(cohortId, programCode, { email });
      submitRegistrationForm(formData, status => {
        if (status.includes('contact_id')) {
          // console.log('Form submitted successfully');
          window.location.href = thankYouRedirect + document.location.search;
        }
      });
    } else {
      setFormError(true);
      console.log('Form is not valid');
    }
  };

  const formFieldClass = classnames(styles['form-element'], styles['inline-style']);
  const wideSelectFieldClass = classnames(styles['form-element'], styles['wide-select']);
  // const narrowSelectFieldClass = classnames(styles['form-element'], styles['narrow-select']);
  const extraWideSelectFieldClass = classnames(styles['form-element'], styles['extra-wide-select']);

  return (
    <div className={styles.apply1Form}>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="cohort_id" value={cohortId} />
        <input type="hidden" name="program_code" value={programCode} />
        {page === 1 && (
          <>
            <h1> Step 1 - About You</h1>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="firstName"
                fieldType="text"
                labelText="First name*"
                onBlur={() => {
                  handleValidate('firstName');
                }}
                onChange={handleChangeFirstName}
                textValue={firstName}
                error={firstNameError}
                placeholder={defaults.firstName}
              />
              <TextField
                fieldClass={formFieldClass}
                fieldName="lastName"
                fieldType="text"
                labelText="Last name*"
                onBlur={() => {
                  handleValidate('lastName');
                }}
                onChange={handleChangeLastName}
                textValue={lastName}
                error={lastNameError}
                placeholder={defaults.lastName}
              />
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="email"
                fieldType="email"
                labelText="Email*"
                onBlur={() => {
                  handleValidate('email');
                }}
                onChange={handleChangeEmail}
                textValue={email}
                error={emailError}
                placeholder={defaults.email}
              />
              <TextField
                fieldClass={formFieldClass}
                fieldType="tel"
                fieldName="phone"
                labelText="Phone*"
                onBlur={() => {
                  handleValidate('phone');
                }}
                onChange={handleChangePhone}
                textValue={phoneMasked}
                error={phoneError}
                placeholder={defaults.phone}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="amzState"
                labelText="What state do you work in?*"
                onBlur={() => {
                  handleValidate('amzState');
                }}
                onChange={handleChangeAmzState}
                error={amzStateError}
                options={locationOptions}
                value={amzState}
              />
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="amzSiteCode"
                labelText="What is your Amazon site code?*"
                onBlur={() => {
                  handleValidate('amzSiteCode');
                }}
                onChange={handleChangeAmzSiteCode}
                error={amzSiteCodeError}
                options={siteCodeOptions}
                value={amzSiteCode}
              />
            </div>
            {amzSiteCode === 'not-listed' && (
              <div className={styles['form-content']}>
                <TextField
                  fieldClass={formFieldClass}
                  fieldName="amzSiteCodeFreeText"
                  fieldType="text"
                  placeholder=""
                  onBlur={() => {}}
                  labelText="If your site code is not listed, what is your site code?"
                  onChange={e => setAmzSiteCodeFreeText(e.target.value)}
                  textValue={amzSiteCodeFreeText}
                />
              </div>
            )}
            <div className={styles['form-content']}>
              <TextField
                fieldClass={extraWideSelectFieldClass}
                fieldName="currentRole"
                placeholder="Type your answer"
                fieldType="text"
                labelText="What is your current role at Amazon?*"
                onBlur={() => {
                  handleValidate('currentRole');
                }}
                onChange={handleChangeCurrentRole}
                error={currentRoleError}
                textValue={currentRole}
              />
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="employmentStatus"
                labelText="Are you a full time employee?*"
                onBlur={() => {
                  handleValidate('employmentStatus');
                }}
                onChange={handleChangeEmploymentStatus}
                error={employmentStatusError}
                options={[
                  { label: 'Full time', value: 'full time' },
                  { label: 'Part time', value: 'part time' },
                ]}
                value={employmentStatus}
              />
            </div>

            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="hasTimeCommitment"
                labelText="Are you able to commit to 8-10 hours of study per week between February and June, 2025?*"
                onBlur={() => {
                  handleValidate('hasTimeCommitment');
                }}
                onChange={handleChangeHasTimeCommitment}
                error={hasTimeCommitmentError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                value={hasTimeCommitment}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={extraWideSelectFieldClass}
                fieldName="educationLevel"
                labelText="What is your highest level of education?*"
                onBlur={() => {
                  handleValidate('educationLevel');
                }}
                onChange={handleChangeEducationLevel}
                error={educationLevelError}
                options={[
                  { label: 'Less than high school', value: 'less than high school' },
                  { label: 'Some high school', value: 'some high school' },
                  { label: 'High school graduate or equivalent (GED)', value: 'high school graduate' },
                  { label: 'Some college', value: 'some college' },
                  { label: 'Associate degree (2-year college program)', value: 'associate' },
                  { label: "Bachelor's degree (4-year college program)", value: 'bachelor' },
                  { label: "Master's degree or professional degree (MA, MS, MD, lawyer, Ph.D)", value: 'advanced' },
                ]}
                value={educationLevel}
              />
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="collegeFocus"
                fieldType="text"
                labelText="If you have a college degree, what did you study?"
                placeholder="Type your answer"
                onBlur={() => {}}
                onChange={e => setCollegeFocus(e.target.value)} // not required, so don't need handler function
                textValue={collegeFocus}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="hasComputerLiteracy"
                labelText="Have you ever used Microsoft Excel or Google Sheets before and feel comfortable using this tool(s) to create a basic formula?*"
                onBlur={() => {
                  handleValidate('hasComputerLiteracy');
                }}
                onChange={handleChangeHasComputerLiteracy}
                error={hasComputerLiteracyError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                value={hasComputerLiteracy}
              />
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="currentCertifications"
                fieldType="text"
                labelText="Please list any other certificates, certifications or credentials you have obtained in the field of data analytics or technology (ie Tableau Desktop Specialist, MIT Data Analytics Certificate, etc)"
                placeholder="Type your answer"
                onBlur={() => {}}
                onChange={e => setCurrentCertifications(e.target.value)} // not required, so don't need handler function
                textValue={currentCertifications}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="hasPreviousDataAnalysis"
                labelText="Have you ever held a job where you needed to use, collect, or analyze data?*"
                onBlur={() => {
                  handleValidate('hasPreviousDataAnalysis');
                }}
                onChange={handleChangeHasPreviousDataAnalysis}
                error={hasPrevisouDataAnalysisError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                value={hasPreviousDataAnalysis}
              />
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="previousDataAnalysisPosition"
                fieldType="text"
                labelText="If yes, what was your job title and company?"
                onBlur={() => {}}
                onChange={handleChangePreviousDataAnalysisPosition}
                textValue={previousDataAnalysisPosition}
                placeholder="Type your answer"
              />
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <label htmlFor="programGoal" className={styles['form-label']}>
                  Personal statement: in a few paragraphs, please tell us about: (1) yourself, (2) your career goals,
                  and (3) your motivation for completing this program. (250 words max)*
                </label>
                <div className={styles['field-wrapper']}>
                  <textarea
                    type="text"
                    id="programGoal"
                    placeholder="Type your answer here..."
                    onChange={handleChangeProgramGoal}
                    onBlur={() => {
                      handleValidate('programGoal');
                    }}
                    name="programGoal"
                    value={programGoal}
                  />
                  {programGoalError && ErrorMessage(programGoalError)}
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={extraWideSelectFieldClass}
                fieldName="roleTimeline"
                labelText="What is your timeline for wanting to apply to a new role?*"
                onBlur={() => {
                  handleValidate('roleTimeline');
                }}
                onChange={handleChangeRoleTimeline}
                error={roleTimelineError}
                options={[
                  { label: 'As soon as possible', value: 'as soon as possible' },
                  { label: 'Within the next 6-12 months', value: 'within 6-12 months' },
                  { label: 'Within the next 12-18 months', value: 'within 12-18 months' },
                  {
                    label: "I'm not interested in moving to a new role",
                    value: 'not interesting in new role',
                  },
                  { label: "I'm not sure", value: 'not sure' },
                ]}
                value={roleTimeline}
              />
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <label htmlFor="careerGoal" className={styles['form-label']}>
                  Why do you want to become a data analyst? <br />
                  What (if anything) do you already know about the field of data analysis or marketing analysis? (500
                  characters max)*
                </label>
                <div className={styles['field-wrapper']}>
                  <textarea
                    type="text"
                    id="careerGoal"
                    placeholder="Type your answer here..."
                    onChange={handleChangecareerGoal}
                    onBlur={() => handleValidate('careerGoal')}
                    name="careerGoal"
                    value={careerGoal}
                    maxLength={500}
                  />
                  {careerGoalError && ErrorMessage(careerGoalError)}
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <label htmlFor="programChallenge" className={styles['form-label']}>
                  What do you think might be your biggest challenge in completing this program and getting a new job?
                  (500 characters max)*
                </label>
                <div className={styles['field-wrapper']}>
                  <textarea
                    type="text"
                    id="programChallenges"
                    placeholder="Type your answer here..."
                    onChange={handleChangeProgramChallenges}
                    onBlur={() => handleValidate('programChallenges')}
                    name="programChallenges"
                    value={programChallenges}
                    maxLength={500}
                  />
                  {programChallengesError && ErrorMessage(programChallengesError)}
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="linkedInProfileURL"
                fieldType="text"
                labelText="Please provide a link to your LinkedIn profile, if you have one."
                onBlur={() => {
                  handleValidate('linkedIn');
                }}
                onChange={handleLinkedInProfileURL}
                textValue={linkedInProfileURL}
                error={linkedInProfileURLError}
                placeholder="Paste your LinkedIn URL here"
              />
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="textConsent" className={styles['check-label']}>
                    <input
                      type="checkbox"
                      name="text_consent"
                      id="field[15]"
                      checked={textConsent}
                      onChange={handletextConsent}
                      defaultValue="false"
                    />
                    <div className={styles['check-box-text']}>
                      I provide my consent for Pathstream or its partners to contact me using text messaging at the
                      phone number provided above.
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="callConsent" className={styles['check-label']}>
                    <input
                      type="checkbox"
                      name="call_consent"
                      id="field[15]"
                      checked={callConsent}
                      onChange={handleCallConsent}
                      defaultValue="false"
                    />
                    <div className={styles['check-box-text']}>
                      I provide my consent for Pathstream or its partners to contact me using calls at the phone number
                      provided above.
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="termsConsent" className={styles['check-label']}>
                    <input
                      type="checkbox"
                      name="terms_consent"
                      id="field[15]"
                      checked={termsConsent}
                      onChange={handleTermsConsent}
                      defaultValue="false"
                    />
                    <div className={styles['check-box-text']}>
                      I agree to Pathstream&apos;s{' '}
                      <a href="https://pathstream.com/terms-of-use" target="_blank" rel="noreferrer">
                        terms of use
                      </a>{' '}
                      and{' '}
                      <a href="https://pathstream.com/privacy-policy" target="_blank" rel="noreferrer">
                        privacy policy
                      </a>
                      .*
                    </div>
                  </label>
                  {termsConsentError && ErrorMessage(termsConsentError)}
                </div>
              </div>
            </div>
            <br />
            {formError && (
              <div className={styles['form-error']}>
                You are missing a required field. Please check your responses to continue.
              </div>
            )}
            <div className={styles['form-buttons']}>
              <div className={classnames(styles['button-wrapper'], styles['inline-style'])}>
                {buttonDisabled && 'Loading...'}
                {!buttonDisabled && (
                  <button
                    id="form-164-next"
                    className={styles.next}
                    type="button"
                    disabled={buttonDisabled}
                    onClick={handleNextPage}>
                    Next {`>`}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        {page === 2 && (
          <>
            <h1> Step 2 - Math Assessment</h1>
            <div className={styles['quiz-instructions']}>
              <p>
                Please answer the following questions, which will provide a baseline assessment for some of the types of
                mathematical questions Data Analysts handle in their day to day jobs.
              </p>
            </div>
            <MCQuestion
              questionText="1. A forklift operator needs to move pallets from the receiving area to storage. If each trip takes 10 minutes and the operator has 5 hours to work, excluding a 30-minute lunch break, how many trips can the operator make?"
              questionNumber={1}
              answerOptions={['25', '27', '32', '33']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="2. A warehouse receives an order for 800 items that need to be packed into small, medium, and large boxes. If each box can hold 10, 20, and 50 items, how many large boxes are needed if 8 small boxes and 6 medium boxes are packed full, in order to ship the order?"
              questionNumber={2}
              answerOptions={['8', '10', '12', '14']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="3. The warehouse operates two shifts: morning and night. If the morning shift processes orders 3 times faster than the night shift and together they process 900 orders, how many orders does the morning shift process?"
              questionNumber={3}
              answerOptions={['300', '325', '675', '800']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="4. There are three types of tasks, A, B, and C, that take 1, 2 and 3 minutes, respectively, to complete. How many tasks can be completed in 8 hours by one person that spends 20% of their time on task A, 70% of the time on task B, and 10% of their time on task C?"
              questionNumber={4}
              answerOptions={['280', '300', '320', '340']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="5. A team leader finds that an error occurred in the order processing, and 120 orders need to be reprocessed within 8 hours to meet the shipping deadline. If each order takes 15 minutes to process, how many workers are needed to complete this task on time if each worker processes orders at the same rate?"
              questionNumber={5}
              answerOptions={['4', '5', '6', '7']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="6. A manager has run a report to determine how long 5 team members spent completing their most recent tasks, which are 55 min, 1 hr, 1.1 hrs, 1 ⅛ hrs, and 1 hr 10 min. What is the average (mean) time the team takes to complete the task?"
              questionNumber={6}
              answerOptions={[
                '1 hr, 1 min, 12 sec',
                '1 hr, 2 min, 6 sec',
                '1 hr, 2 min, 18 sec',
                '1 hr, 3 min, 42 sec',
              ]}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="7. A warehouse needs to purchase new forklifts for moving pallets. Each forklift costs $15,000, and the warehouse has been given a budget of $130,000 for this purchase. If the warehouse also needs to set aside 20% of its budget for maintenance costs, how many forklifts can the warehouse afford to purchase?"
              questionNumber={7}
              answerOptions={['5', '6', '7', '8']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            {formError && (
              <div className={styles['form-error']}>
                Something is missing. Ensure all questions are answered before applying.
              </div>
            )}
            <div className={styles['form-buttons']}>
              <div className={classnames(styles['button-wrapper'], styles['inline-style'])}>
                {!buttonDisabled && (
                  <button
                    id="form-164-back"
                    className={styles.next}
                    type="button"
                    disabled={buttonDisabled}
                    onClick={handleNextPage}>
                    {`< `}Back
                  </button>
                )}
              </div>
              <div className={classnames(styles['button-wrapper'], styles['inline-style'])}>
                {buttonDisabled && 'Processing application...'}
                {!buttonDisabled && (
                  <button id="form-164-submit" className={styles.submit} type="submit" disabled={buttonDisabled}>
                    Apply
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  );
};
