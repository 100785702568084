import './index.scss';
import { dynamicallyLoadCompanionCss } from './utils/dynamicallyLoadCompanionCss';
import { replaceWithReactComponent } from './utils/react';
import { loadDatadog } from './utils/datadog';
import { analyticsEnabled } from './utils/environment';
import { analytics } from './analytics/analytics';
import { setIdentityByCart } from './analytics/identity/cart';
import { loadCart } from './cart';
import { RequestDemoForm } from './RequestDemoForm';
import { Apply1FormSalesOps } from './Forms/Apply1FormSalesOps';
import { Apply1FormData } from './Forms/Apply1FormData';
import { WelcomeToPathstream } from './WelcomeToPathstream';
import { customEventScroll, customEventButtonClicks } from './analytics/customEvents';
import { EligibilitySearch } from './EligibilitySearch';
import { EligibilityResults } from './EligibilityResults';
import { runAmplitudeExperiment } from './analytics/amplitudeExperiment';
import { IneligibleForm } from './Forms/IneligibleForm';
import { Apply1FormProcessAsst } from './Forms/Apply1FormProcessAsst';

// console.log('blogjs Dev mode enabled.');
dynamicallyLoadCompanionCss();
runAmplitudeExperiment();
if (analyticsEnabled) loadDatadog();
loadCart().then(cart => (cart ? setIdentityByCart(cart) : null));
analytics.page();
customEventScroll();

// setUser('id', 'Name', 'email@email.com');
const pageId = window.wpSiteInfo.id;
customEventButtonClicks(pageId);

replaceWithReactComponent(Apply1FormData, '#apply1-form-data');
replaceWithReactComponent(Apply1FormSalesOps, '#apply1-form-sales-ops');
replaceWithReactComponent(Apply1FormProcessAsst, '#apply1-form-process-asst');

if (pageId === '16945' || pageId === '8879') {
  // Only for /programs page
  replaceWithReactComponent(EligibilitySearch, '#eligibility-search');
  replaceWithReactComponent(EligibilityResults, '#eligibility-results');
  replaceWithReactComponent(IneligibleForm, '#ineligible-form');
}

if (pageId === '372' || pageId === '357' || pageId === '376') {
  // explore pages: /data-analyst-career,  /project-management-career  /salesforce-admin-career
  replaceWithReactComponent(IneligibleForm, '#ineligible-form');
}

if (pageId === '8309') {
  // Only for /request-a-demo page
  replaceWithReactComponent(RequestDemoForm, '#request-a-demo-form');
}

if (pageId === '5941') {
  // Only for /welcome-to-pathstream
  replaceWithReactComponent(WelcomeToPathstream, '#welcomeToPathstream');
}

if (pageId === '15999') {
  // Only for /shane-sandbox
  replaceWithReactComponent(Apply1FormProcessAsst, '#apply1-form-process-asst');
}
