import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import * as EmailValidator from 'email-validator';
import * as styles from './Apply1Form.module.scss';
import TextField from './formComponents/TextField';
import { submitIneligibleForm } from '../api/registration';
import { customEventIdentifyUser, customEventIneligibleFormSubmit } from '../analytics/customEvents';

export const IneligibleForm = () => {
  const defaults = {
    firstName: 'Type your first name',
    lastName: 'Type your last name',
    email: 'Type your email',
    employer: 'Type your current employer',
  };

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [employer, setEmployer] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [employerError, setEmployerError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState('');

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.get('employer')) {
      setEmployer(url.searchParams.get('employer'));
    }
  }, []);

  const handleChangeFirstName = e => {
    setFirstName(e.target.value);
    setFirstNameError('');
  };

  const handleChangeLastName = e => {
    setLastName(e.target.value);
    setLastNameError('');
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const handleChangeEmployer = e => {
    setEmployer(e.target.value);
    setEmployerError('');
  };

  const handleValidate = field => {
    let valid = true;

    if (field === 'firstName') {
      if (!firstName) {
        setFirstNameError('First name is required');
        valid = false;
      } else {
        setFirstNameError('');
      }
    }
    if (field === 'lastName') {
      if (!lastName) {
        setLastNameError('Last name is required');
        valid = false;
      } else {
        setLastNameError('');
      }
    }
    if (field === 'email') {
      if (!email || !EmailValidator.validate(email)) {
        setEmailError('Email is required and must be a valid email address');
        valid = false;
      } else {
        setEmailError('');
      }
    }
    if (field === 'employer') {
      if (!employer) {
        setEmployerError('Employer is required');
        valid = false;
      } else {
        setEmployerError('');
      }
    }
    return valid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (
      handleValidate('email') &&
      handleValidate('employer') &&
      handleValidate('firstName') &&
      handleValidate('lastName')
    ) {
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email,
        employer_name: employer, // employer's name from form
        entry: 'ineligible_employer_lead',
      };

      customEventIdentifyUser(email, firstName, lastName, '');
      customEventIneligibleFormSubmit({ email, ineligible_employer: employer });
      submitIneligibleForm(formData, status => {
        if (status.includes('contact_id')) {
          setFormSubmitted(true);
        } else {
          console.log('error submitting form');
          setFormSubmitted(true);
        }
      });
    }
  };

  const formFieldClass = classnames(styles['form-element'], styles['inline-style'], styles['light-style']);

  return !formSubmitted ? (
    <div className={styles.apply1Form}>
      <form onSubmit={handleSubmit}>
        <div className={styles['form-content']}>
          <TextField
            fieldClass={formFieldClass}
            fieldName="firstName"
            fieldType="text"
            labelText="First name*"
            onBlur={() => {
              // handleValidate('firstName');
            }}
            onChange={handleChangeFirstName}
            textValue={firstName}
            error={firstNameError}
            placeholder={defaults.firstName}
          />
          <TextField
            fieldClass={formFieldClass}
            fieldName="lastName"
            fieldType="text"
            labelText="Last name*"
            onBlur={() => {
              // handleValidate('lastName');
            }}
            onChange={handleChangeLastName}
            textValue={lastName}
            error={lastNameError}
            placeholder={defaults.lastName}
          />
          <TextField
            fieldClass={formFieldClass}
            fieldName="email"
            fieldType="email"
            labelText="Email*"
            onBlur={() => {
              handleValidate('email');
            }}
            onChange={handleChangeEmail}
            textValue={email}
            error={emailError}
            placeholder={defaults.email}
          />
          <TextField
            fieldClass={formFieldClass}
            fieldName="employer"
            fieldType="text"
            labelText="Current employer*"
            onBlur={() => {
              // handleValidate('employer');
            }}
            onChange={handleChangeEmployer}
            textValue={employer}
            error={employerError}
            placeholder={defaults.employer}
          />
        </div>
        <div className={classnames(styles['button-wrapper'], styles['inline-style'])}>
          <button id="form-ineligible-submit" type="submit" className={styles.submit}>
            Keep me updated
          </button>
        </div>
      </form>
    </div>
  ) : (
    <h3 className={styles['ineligible-text']}>
      We have received your information, and will be in touch soon. In the meantime, read more about our program
      offerings below.
    </h3>
  );
};
