import { analytics } from './analytics';
import { generateUuidFromEmail } from './identity/uuid';
import { getButtonsToTrack } from '../api/airtableConfigs';

export const customEventButtonClicks = async activePageID => {
  const buttonsToListen = await getButtonsToTrack(activePageID);
  if (buttonsToListen.length === 0) return;
  buttonsToListen.forEach(buttonInfo => {
    if (Number(buttonInfo.page_id) !== Number(activePageID)) return;
    // console.log('setting event by', buttonInfo.selector_type);
    const selectorType = buttonInfo.selector_type;
    const buttonName = buttonInfo.button_name;
    const { selector } = buttonInfo;
    const buttons = [];

    if (selectorType === 'id') {
      buttons.push(document.getElementById(selector));
    } else if (selectorType === 'className') {
      const links = document.getElementsByClassName(selector);
      if (links) {
        Array.from(links).forEach(item => {
          buttons.push(item);
        });
      }
    }
    if (buttons.length === 0) return;
    buttons.forEach(button => {
      button.addEventListener('click', () => {
        const params = {
          url: window.location.href,
          button_id: selector,
          button_name: buttonName,
        };
        if (button.nodeName === 'A') {
          params.href = button.href;
        }
        analytics.track('wp_button_click', params);
      });
    });
  });
};

export const customEventScroll = () => {
  // expects analytics.track()
  let lastScroll;
  const eventName = 'scrollPast';
  const params = {
    url: window.location.href,
    percent: null,
    position: null,
    totalHeight: null,
  };

  const myScrollFunc = () => {
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );
    const y = window.scrollY;
    const scrollPercentage = y / scrollHeight;
    if (scrollPercentage >= 0 && scrollPercentage < 0.2 && lastScroll !== 0) {
      lastScroll = 0;
      params.percent = lastScroll;
      params.position = y;
      params.totalHeight = scrollHeight;
      analytics.track(eventName, params);
    } else if (scrollPercentage >= 0.2 && scrollPercentage < 0.4 && lastScroll !== 0.2) {
      lastScroll = 0.2;
      params.percent = lastScroll;
      params.position = y;
      params.totalHeight = scrollHeight;
      analytics.track(eventName, params);
    } else if (scrollPercentage >= 0.4 && scrollPercentage < 0.6 && lastScroll !== 0.4) {
      lastScroll = 0.4;
      params.percent = lastScroll;
      params.position = y;
      params.totalHeight = scrollHeight;
      analytics.track(eventName, params);
    } else if (scrollPercentage >= 0.6 && scrollPercentage < 0.8 && lastScroll !== 0.6) {
      lastScroll = 0.6;
      params.percent = lastScroll;
      params.position = y;
      params.totalHeight = scrollHeight;
      analytics.track(eventName, params);
    } else if (scrollPercentage >= 0.8 && scrollPercentage < 1 && lastScroll !== 0.8) {
      lastScroll = 0.8;
      params.percent = lastScroll;
      params.position = y;
      params.totalHeight = scrollHeight;
      analytics.track(eventName, params);
    }
  };
  window.addEventListener('scroll', myScrollFunc);
};

export const customEventIdentifyUser = (userEmail, userFirstName, userLastName, userPhone) => {
  const cleanEmail = userEmail.trim().toLowerCase();
  const payload = {
    email: cleanEmail,
    firstName: userFirstName,
    lastName: userLastName,
    name: `${userFirstName} ${userLastName}`,
    full_name: `${userFirstName} ${userLastName}`,
    phone: userPhone,
  };
  analytics.identify(generateUuidFromEmail(cleanEmail), payload);
};

export const customEventApply1Submit = (cohortId, programCode, params) => {
  analytics.track('wp_apply1Form_submit', {
    url: window.location.href,
    cohort_id: cohortId,
    program_code: programCode,
    ...params,
  });
};

export const customEventIneligibleFormSubmit = params => {
  analytics.track('wp_ineligible_submit', {
    url: window.location.href,
    ...params,
  });
};

export const customEventPartialFormSubmit = params => {
  analytics.track('wp_partial_form_submit', {
    url: window.location.href,
    ...params,
  });
};

export const customEventInelegibleSearch = params => {
  analytics.track('wp_ineligible_search', {
    url: window.location.href,
    ...params,
  });
};

export const customEventB2BRequestDemoSubmit = params => {
  analytics.track('b2b_requestDemo_submit', {
    ...params,
  });
};

export const customEventIdentifyB2BUser = (email, params) => {
  analytics.identify(generateUuidFromEmail(email), { ...params });
};
