import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import * as EmailValidator from 'email-validator';
import * as styles from './Apply1Form.module.scss';
import TextField from './formComponents/TextField';
import SelectField from './formComponents/SelectField';
import { submitRegistrationForm } from '../api/registration';
import { customEventApply1Submit, customEventIdentifyUser } from '../analytics/customEvents';
import stateAndSiteCodes from './StateAndSiteCodeValues.json';

export const Apply1FormData = () => {
  const [cohortId, setCohortId] = useState('');
  const [programCode, setProgramCode] = useState('');
  const [thankYouRedirect, setThankYouRedirect] = useState('');
  const [ineligibleRedirect, setIneligibleRedirect] = useState('');

  const defaults = {
    firstName: 'Type your first name',
    lastName: 'Type your last name',
    email: 'Type your email',
    phone: 'e.g. (555) 555-5555',
  };

  const stateNames = Object.getOwnPropertyNames(stateAndSiteCodes);
  const locationOptions = stateNames.map(state => ({ value: state, label: stateAndSiteCodes[state].state_name }));
  locationOptions.push({ value: 'none', label: 'None' });

  // fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [amzCurrentEmployee, setAmzCurrentEmployee] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneMasked, setPhoneMasked] = useState('');
  const [amzState, setAmzState] = useState('');
  const [hasTimeCommitment, setHasTimeCommitment] = useState('');
  const [hasWorkingEnglish, setHasWorkingEnglish] = useState('');
  const [hasHighSchoolMath, setHasHighSchoolMath] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [collegeFocus, setCollegeFocus] = useState('');
  const [currentCertifications, setCurrentCertifications] = useState('');
  const [hasPreviousDataAnalysis, setHasPreviousDataAnalysis] = useState('');
  const [previousDataAnalysisPosition, setPreviousDataAnalysisPosition] = useState('');
  const [hasComputerLiteracy, setHasComputerLiteracy] = useState('');
  const [programGoal, setProgramGoal] = useState('');
  const [programChallenges, setProgramChallenges] = useState('');
  const [linkedInProfileURL, setLinkedInProfileURL] = useState('');
  const [textConsent, setTextConsent] = useState(false);
  const [callConsent, setCallConsent] = useState(false);
  const [termsConsent, setTermsConsent] = useState(false);

  // errors
  const [emailError, setEmailError] = useState('');
  const [amzCurrentEmployeeError, setAmzCurrentEmployeeError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [amzStateError, setAmzStateError] = useState('');
  const [hasTimeCommitmentError, setHasTimeCommitmentError] = useState('');
  const [hasWorkingEnglishError, setHasWorkingEnglishError] = useState('');
  const [hasHighSchoolMathError, setHasHighSchoolMathError] = useState('');
  const [educationLevelError, setEducationLevelError] = useState('');
  const [hasPreviousDataAnalysisError, setHasPreviousDataAnalysisError] = useState('');
  const [hasComputerLiteracyError, setHasComputerLiteracyError] = useState('');
  const [linkedInProfileURLError, setLinkedInProfileURLError] = useState('');

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const redirect = React.useRef();

  useEffect(() => {
    const formOptions = document.querySelector('#apply1-form-data');
    if (
      !formOptions.dataset.cohortid ||
      !formOptions.dataset.programcode ||
      !formOptions.dataset.thankyouredirect ||
      !formOptions.dataset.ineligibleredirect
    ) {
      // eslint-disable-next-line
      alert('Error: Form must have data-cohortId, data-programCode, and data-thankYouRedirect attributes.');
    } else {
      setCohortId(formOptions.dataset.cohortid);
      setProgramCode(formOptions.dataset.programcode);
      setThankYouRedirect(formOptions.dataset.thankyouredirect);
      setIneligibleRedirect(formOptions.dataset.ineligibleredirect);
      redirect.current = formOptions.dataset.thankyouredirect;
    }
  }, []);

  const handleChangeFirstName = e => {
    setFirstNameError('');
    setFirstName(e.target.value);
  };

  const handleChangeLastName = e => {
    setLastNameError('');
    setLastName(e.target.value);
  };

  const handleChangeEmail = e => {
    setEmailError('');
    setEmail(e.target.value);
  };

  const handleChangeAmzCurrentEmployee = e => {
    setAmzCurrentEmployeeError('');
    setAmzCurrentEmployee(e.target.value);
  };

  const handleChangePhone = e => {
    setPhoneError('');
    const phoneNum = e.target.value.replace(/\D/g, '');
    setPhone(phoneNum.length > 10 ? phoneNum.slice(0, 10) : phoneNum);
    // this masks the phone input to (xxx) xxx-xxxx
    const val = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const phoneFormat = !val[2] ? val[1] : `(${val[1]}) ${val[2]}${val[3] ? `-${val[3]}` : ''}`;
    setPhoneMasked(phoneFormat);
  };

  const handleChangeAmzState = e => {
    setAmzStateError('');
    setAmzState(e.target.value);
  };

  const handletextConsent = () => {
    setTextConsent(!textConsent);
  };

  const handleCallConsent = () => {
    setCallConsent(!callConsent);
  };

  const handleTermsConsent = () => {
    setTermsConsent(!termsConsent);
  };

  const handleChangeHasTimeCommitment = e => {
    setHasTimeCommitmentError('');
    setHasTimeCommitment(e.target.value);
  };

  const handleChangeHasWorkingEnglish = e => {
    setHasWorkingEnglish(e.target.value);
  };

  const handleChangeHasHighSchoolMath = e => {
    setHasHighSchoolMath(e.target.value);
  };

  const handleChangeEducationLevel = e => {
    setEducationLevel(e.target.value);
  };

  const handleChangeCollegeFocus = e => {
    setCollegeFocus(e.target.value);
  };

  const handleChangeCurrentCertifications = e => {
    setCurrentCertifications(e.target.value);
  };

  const handleChangeHasPreviousDataAnalysis = e => {
    setHasPreviousDataAnalysis(e.target.value);
  };

  const handlePreviousDataAnalysisPosition = e => {
    setPreviousDataAnalysisPosition(e.target.value);
  };

  const handleChangeHasComputerLiteracy = e => {
    setHasComputerLiteracy(e.target.value);
  };

  const handleChangeProgramGoal = e => {
    setProgramGoal(e.target.value);
  };

  const handleChangeProgramChallenges = e => {
    setProgramChallenges(e.target.value);
  };

  const handleLinkedInProfileURL = e => {
    setLinkedInProfileURLError('');
    setLinkedInProfileURL(e.target.value);
  };

  const handleValidate = field => {
    let valid = true;
    // console.log('validating ', field);
    if (field === 'email') {
      if (!email) {
        setEmailError(`Email is required`);
        valid = false;
      } else if (!EmailValidator.validate(email)) {
        setEmailError(`This email doesn't look right`);
        valid = false;
      } else {
        setEmailError('');
      }
    }
    if (field === 'firstName') {
      if (!firstName) {
        setFirstNameError('First name is required');
        valid = false;
      } else {
        setFirstNameError('');
      }
    }

    if (field === 'lastName') {
      if (!lastName) {
        setLastNameError('Last name is required');
        valid = false;
      } else {
        setLastNameError('');
      }
    }
    if (field === 'amzCurrentEmployee') {
      if (!amzCurrentEmployee) {
        setAmzCurrentEmployeeError('This is required');
        valid = false;
      } else {
        setAmzCurrentEmployeeError('');
      }
    }
    if (amzCurrentEmployee === 'yes') {
      if (field === 'phone') {
        if (!phone) {
          setPhoneError('Phone is required');
          valid = false;
        } else {
          setPhoneError('');
        }
      }

      if (field === 'amzState') {
        if (!amzState) {
          setAmzStateError('State is required');
          valid = false;
        } else {
          setAmzStateError('');
        }
      }
    }
    if (field === 'hasTimeCommitment') {
      if (!hasTimeCommitment) {
        setHasTimeCommitmentError('This is required');
        valid = false;
      } else {
        setHasTimeCommitmentError('');
      }
    }
    if (field === 'hasWorkingEnglish') {
      if (!hasWorkingEnglish) {
        setHasWorkingEnglishError('This is required');
        valid = false;
      } else {
        setHasWorkingEnglishError('');
      }
    }
    if (field === 'hasHighSchoolMath') {
      if (!hasHighSchoolMath) {
        setHasHighSchoolMathError('This is required');
        valid = false;
      } else {
        setHasHighSchoolMathError('');
      }
    }
    if (field === 'educationLevel') {
      if (!educationLevel) {
        setEducationLevelError('This is required');
        valid = false;
      } else {
        setEducationLevelError('');
      }
    }
    if (field === 'hasPreviousDataAnalysis') {
      if (!hasPreviousDataAnalysis) {
        setHasPreviousDataAnalysisError('This is required');
        valid = false;
      } else {
        setHasPreviousDataAnalysisError('');
      }
    }
    if (field === 'hasComputerLiteracy') {
      if (!hasComputerLiteracy) {
        setHasComputerLiteracyError('This is required');
        valid = false;
      } else {
        setHasComputerLiteracyError('');
      }
    }

    return valid;
  };

  const isEligible = () => {
    if (amzCurrentEmployee === 'no') {
      return false;
    }
    return true;
  };

  const validateAll = () => {
    if (
      handleValidate('firstName') &&
      handleValidate('lastName') &&
      handleValidate('email') &&
      handleValidate('amzCurrentEmployee')
    ) {
      if (!isEligible()) {
        return true; // if not eligible, we don't need to validate the rest of the form, but we still want to submit the form
      }
      if (
        handleValidate('phone') &&
        handleValidate('amzState') &&
        handleValidate('hasTimeCommitment') &&
        handleValidate('hasWorkingEnglish') &&
        handleValidate('hasHighSchoolMath') &&
        handleValidate('educationLevel') &&
        handleValidate('hasPreviousDataAnalysis') &&
        handleValidate('hasComputerLiteracy')
      ) {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validateAll()) {
      setButtonDisabled(true);
      if (!isEligible()) {
        window.location.href = ineligibleRedirect;
        return;
      }
      const formData = {
        cohort_id: cohortId,
        program_code: programCode,
        email,
        first_name: firstName,
        last_name: lastName,
        phone,
        text_consent: textConsent,
        call_consent: callConsent,
        terms_consent: termsConsent,
        external_partner_data: {
          amazon_state: amzState,
          amazon_current_employee: amzCurrentEmployee,
          has_time_commitment: hasTimeCommitment,
          has_working_english: hasWorkingEnglish,
          has_high_school_math: hasHighSchoolMath,
          education_level: educationLevel,
          college_focus: collegeFocus,
          current_certifications: currentCertifications,
          has_previous_data_analysis: hasPreviousDataAnalysis,
          previous_data_analysis_position: previousDataAnalysisPosition,
          has_computer_literacy: hasComputerLiteracy,
          program_goal: programGoal,
          program_challenges: programChallenges,
          linkedin_profile_url: linkedInProfileURL,
        },
      };

      // console.log('formData', formData);
      // console.log('redirect', thankYouRedirect);

      customEventIdentifyUser(email, firstName, lastName, phone);
      customEventApply1Submit(cohortId, programCode, { email });
      submitRegistrationForm(formData, status => {
        if (status.includes('contact_id')) {
          // console.log('Form submitted successfully');
          window.location.href = thankYouRedirect;
        }
      });
    } else {
      console.log('Form is not valid');
    }
  };

  const formFieldClass = classnames(styles['form-element'], styles['inline-style']);
  const wideSelectFieldClass = classnames(styles['form-element'], styles['wide-select']);
  // const narrowSelectFieldClass = classnames(styles['form-element'], styles['narrow-select']);
  const extraWideSelectFieldClass = classnames(styles['form-element'], styles['extra-wide-select']);

  return (
    <div className={styles.apply1Form}>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="cohort_id" value={cohortId} />
        <input type="hidden" name="program_code" value={programCode} />
        <div className={styles['form-content']}>
          <TextField
            fieldClass={formFieldClass}
            fieldName="firstName"
            fieldType="text"
            labelText="First name*"
            onBlur={() => {
              handleValidate('firstName');
            }}
            onChange={handleChangeFirstName}
            textValue={firstName}
            error={firstNameError}
            placeholder={defaults.firstName}
          />
          <TextField
            fieldClass={formFieldClass}
            fieldName="lastName"
            fieldType="text"
            labelText="Last name*"
            onBlur={() => {
              handleValidate('lastName');
            }}
            onChange={handleChangeLastName}
            textValue={lastName}
            error={lastNameError}
            placeholder={defaults.lastName}
          />
          <TextField
            fieldClass={formFieldClass}
            fieldName="email"
            fieldType="email"
            labelText="Email*"
            onBlur={() => {
              handleValidate('email');
            }}
            onChange={handleChangeEmail}
            textValue={email}
            error={emailError}
            placeholder={defaults.email}
          />
        </div>
        <div className={styles['form-content']}>
          <SelectField
            fieldClass={wideSelectFieldClass}
            fieldName="amzCurrentEmployee"
            labelText="Are you an Amazon employee?"
            onBlur={() => {
              handleValidate('amzCurrentEmployee');
            }}
            onChange={handleChangeAmzCurrentEmployee}
            error={amzCurrentEmployeeError}
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
          />
        </div>
        {amzCurrentEmployee === 'yes' && (
          <>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldType="tel"
                fieldName="phone"
                labelText="Phone*"
                onBlur={() => {
                  handleValidate('phone');
                }}
                onChange={handleChangePhone}
                textValue={phoneMasked}
                error={phoneError}
                placeholder={defaults.phone}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="amzState"
                labelText="What state do you work in?*"
                onBlur={() => {
                  handleValidate('amzState');
                }}
                onChange={handleChangeAmzState}
                error={amzStateError}
                options={locationOptions}
              />
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="hasTimeCommitment"
                labelText="Are you able to commit to 10-15 hours of study per week?*"
                onBlur={() => {
                  handleValidate('hasTimeCommitment');
                }}
                onChange={handleChangeHasTimeCommitment}
                error={hasTimeCommitmentError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={extraWideSelectFieldClass}
                fieldName="hasWorkingEnglish"
                labelText="Have you previously held a job where you need to read, write and speak in English?*"
                onBlur={() => {
                  handleValidate('hasWorkingEnglish');
                }}
                onChange={handleChangeHasWorkingEnglish}
                error={hasWorkingEnglishError}
                options={[
                  { label: 'Yes, I am comfortable reading, writing and speaking in English on the job', value: 'yes' },
                  {
                    label: 'Not yet, but I am ready to ready to read, write and speak in English on the job',
                    value: 'ready',
                  },
                  {
                    label: 'Not yet and I do not yet feel ready to read, write and speak in English on the job',
                    value: 'no',
                  },
                ]}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="hasHighSchoolMath"
                labelText="Have you completed high school level algebra and feel comfortable solving simple equations or using variables?*"
                onBlur={() => {
                  handleValidate('hasHighSchoolMath');
                }}
                onChange={handleChangeHasHighSchoolMath}
                error={hasHighSchoolMathError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                  { label: "I'm not sure", value: 'not sure' },
                ]}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={extraWideSelectFieldClass}
                fieldName="educationLevel"
                labelText="What is your highest level of education?*"
                onBlur={() => {
                  handleValidate('educationLevel');
                }}
                onChange={handleChangeEducationLevel}
                error={educationLevelError}
                options={[
                  { label: 'Less than high school', value: 'less than high school' },
                  { label: 'Some high school', value: 'some high school' },
                  { label: 'High school diploma or GED', value: 'high school graduate' },
                  { label: 'Some college', value: 'some college' },
                  { label: 'Associate degree (2-year college program)', value: 'associate' },
                  { label: "Bachelor's degree (4-year college program)", value: 'bachelor' },
                  { label: "Master's degree or professional degree (MA, MS, MD, lawyer, Ph.D)", value: 'advanced' },
                ]}
              />
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="collegeFocus"
                fieldType="text"
                labelText="If you have a college degree, what did you study?"
                onChange={handleChangeCollegeFocus}
                textValue={collegeFocus}
                placeholder="Type your answer"
              />
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="currentCertifications"
                fieldType="text"
                labelText="Please list any other certificates, certifications or credentials you have obtained in the field of data analytics or technology (ie Tableau Desktop Specialist, MIT Data Analytics Certificate, etc)"
                onChange={handleChangeCurrentCertifications}
                textValue={currentCertifications}
                placeholder="Type your answer"
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="hasPreviousDataAnalysis"
                labelText="Have you ever held a job where you needed to use or analyze data?*"
                onBlur={() => {
                  handleValidate('hasPreviousDataAnalysis');
                }}
                onChange={handleChangeHasPreviousDataAnalysis}
                error={hasPreviousDataAnalysisError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
              />
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="previousDataAnalysisPosition"
                fieldType="text"
                labelText="If yes, what was your job title and company?"
                onChange={handlePreviousDataAnalysisPosition}
                textValue={previousDataAnalysisPosition}
                placeholder="Type your answer"
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="hasComputerLiteracy"
                labelText="Have you ever used Microsoft Excel or Google Sheets before and feel comfortable using this tool(s) to create a basic formula?*"
                onBlur={() => {
                  handleValidate('hasComputerLiteracy');
                }}
                onChange={handleChangeHasComputerLiteracy}
                error={hasComputerLiteracyError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
              />
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <label htmlFor="programGoal" className={styles['form-label']}>
                  What is your goal in completing this program?
                </label>
                <div className={styles['field-wrapper']}>
                  <textarea
                    type="text"
                    id="programGoal"
                    placeholder="Type your answer here..."
                    onChange={handleChangeProgramGoal}
                    name="programGoal"
                    value={programGoal}
                    maxLength={250}
                  />
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <label htmlFor="programGoal" className={styles['form-label']}>
                  What do you think might be your biggest challenge in completing this program and getting a new job?
                </label>
                <div className={styles['field-wrapper']}>
                  <textarea
                    type="text"
                    id="programChallenges"
                    placeholder="Type your answer here..."
                    onChange={handleChangeProgramChallenges}
                    name="programChallenges"
                    value={programChallenges}
                    maxLength={250}
                  />
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="linkedInProfileURL"
                fieldType="text"
                labelText="Provide a link to your LinkedIn profile, if you have one."
                onBlur={() => {
                  // handleValidate('currentCertifications');
                }}
                onChange={handleLinkedInProfileURL}
                textValue={linkedInProfileURL}
                error={linkedInProfileURLError}
                placeholder="Paste your LinkedIn URL here"
              />
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="textConsent" className={styles['check-label']}>
                    <input
                      type="checkbox"
                      name="text_consent"
                      id="field[15]"
                      checked={textConsent}
                      onChange={handletextConsent}
                      defaultValue="false"
                    />
                    I provide my consent for Pathstream or its partners to contact me using text messaging at the phone
                    number provided above.
                  </label>
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="callConsent" className={styles['check-label']}>
                    <input
                      type="checkbox"
                      name="call_consent"
                      id="field[15]"
                      checked={callConsent}
                      onChange={handleCallConsent}
                      defaultValue="false"
                    />
                    I provide my consent for Pathstream or its partners to contact me using calls at the phone number
                    provided above.
                  </label>
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="termsConsent" className={styles['check-label']}>
                    <input
                      type="checkbox"
                      name="terms_consent"
                      id="field[15]"
                      checked={termsConsent}
                      onChange={handleTermsConsent}
                      defaultValue="false"
                      required
                    />
                    I agree to Pathstream&apos;s{' '}
                    <a href="https://pathstream.com/terms-of-use" target="blank">
                      terms of use
                    </a>{' '}
                    and <a href="https://pathstream.com/privacy-policy">privacy policy</a>.
                  </label>
                </div>
              </div>
            </div>
            <br />
          </>
        )}
        <div className={classnames(styles['button-wrapper'], styles['inline-style'])}>
          {buttonDisabled && 'Processing application...'}
          {!buttonDisabled && (
            <button id="form-164-submit" className={styles.submit} type="submit" disabled={buttonDisabled}>
              Apply
            </button>
          )}
        </div>
      </form>
    </div>
  );
};
